
import '../index.css';

function Overlay({
  preview,
  text,
  image,
  title,
  verticalTitle,
  children
}) {
  return (
    <>
      <div className="overlay__item" id={preview}>
        { children ?  <p className="">{children}</p> : (
          <>
            {image &&
              <div className="box">
                <div className="box__shadow"></div>
                <img
                  className="box__img box__img--original"
                  src={image}
                  alt="Some"
                />
                <h3 className="box__title">
                  <span className="box__title-inner">{title}</span>
                </h3>
                <h4 className="box__text">
                  <span className="box__text-inner">{verticalTitle}</span>
                </h4>
                {/* <div className="box__deco">&#10014;</div> */}
              </div>
            }
            <p className="overlay__content">{text}</p>
          </>
          )
        }
      </div>

    </>
  );
}

export default Overlay;
