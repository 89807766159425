import image1 from './img/disc1.jpg';
import image2 from './img/2.jpg';
import image3 from './img/11.jpg';
import image4 from './img/5.jpg';
import image5 from './img/disc2.jpeg';

export const contentOverlay = [{
  preview: 'preview-1',
  text: 'Not is a crime',
  image: image5,
  title: 'Not is a crime',
  verticalTitle: 'New album'
},
{
  preview: 'preview-2',
  text: '"1990 llueven hostias en cualquier lugar..."',
  image: image1,
  title: '1990',
  verticalTitle: 'Not is a crime'
},
{
  preview: 'preview-3',
  text: `Año 2016 en CP: 19200. Primera decisión: cantar en castellano. Este hecho marcará el estilo y la identidad del grupo en el futuro. Pequeños detalles se suman a este hecho. Rebajar la fuerza de las distorsiones, componer con más armonía, guitarras con más arpegios pero sin perder agresividad, cuidar las voces, los coros, la pegada de la batería, y por supuesto, cerrar un repertorio y tocar en directo.
  \n 2017, más decisiones. En marzo apostamos por autoeditarnos, grabar los ensayos, tomar notas, apuntes. Hacernos con nuestro propio equipo de grabación, construir una cabina con una acústica determinada. E intentamos grabar: probábamos, probábamos, repetimos y volvíamos a probar, y al final…
  \n Cambiamos nuestro entorno. Desenchufamos, Recogemos, limpiamos y nos vamos a Guadalajara, Sala Oxido. Después de tres meses de duro trabajo (musical y personal) por fin, tenemos resultados. Cumplimos objetivos. Un 7” con 4 temas grabados y mezclados en Apettite estudios producido por Fernando Fernández “FeFe” y Neumático Cheinwz, masterizado por Daniel Altarriba.`,
  // image: image2,
  // title: 'bio',
  // verticalTitle: 'Not is a crime'
},
{
  preview: 'preview-4',
  text: '',
  image: image3,
  title: 'fotos',
  verticalTitle: 'Not is a crime'
},
{
  preview: 'preview-5',
  text: 'eduredon@gmail.com',
  image: image2,
  title: 'contacto',
  verticalTitle: 'Not is a crime'
},
{
  preview: 'preview-6',
  text: '18 de Junio a las 22:00, Lasarte-Oria, Guipúzcoa, Scooter Club Euskadi ',
  image: image4,
  title: 'Conciertos',
  verticalTitle: 'Not is a crime'
}
]