
import './index.css';
import Header from './components/Header/Header';
import Card from './components/Card';
import Overlay from './components/Overlay';
import Slide from './components/Slide/Slide';
import exit from './img/exit.png'
import { contentCard } from './contentCard'
import { contentOverlay } from './contentOverlay'
import SocialMedia from './components/SocialMedia/SocialMedia'

function renderCard () {
  return contentCard.map((element) =>
    <Card
      preview={element.preview}
      rotateInner={element.rotateInner}
      positionDeco={element.positionDeco}
      titleDirection={element.titleDirection}
      titleUpOrDown={element.titleUpOrDown}
      textDirection={element.textDirection}
      textUpOrDown={element.textUpOrDown}
      text={element.text}
      title={element.title}
      hoverTitle={element.hoverTitle}
      rotateText={element.rotateText}
      image={element.image}
      decoration={element.decoration}
    />
  )
}
function renderOverlay () {
  return contentOverlay.map((element) =>
    <Overlay
      preview={element.preview}
      text={element.text}
      image={element.image}
      title={element.title}
      verticalTitle={element.verticalTitle}
    >
      {
        element.preview === 'preview-4' ? <Slide /> : ''
      }
    </Overlay>
  )
}

function App() {
  return (
    <>
      <Header />
      <div className="content">
        <div className="grid">
          { renderCard() }
        </div>
      </div>
      <div className="content">
        <SocialMedia />
      </div>

      <div className="overlay">
        <div className="overlay__reveal"></div>
          { renderOverlay() }
        <button className="overlay__close">
          <img src={exit} alt="exit" />
        </button>
      </div>
    </>
  );
}

export default App;
