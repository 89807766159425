
import '../../index.css';
import './style.css';


function Header() {
  return (
    <>
      <div className="content">
        <header className="codrops-header">
          {/* <h1 className="codrops-header__title comic-text">Neumatico Cheinwz</h1> */}
        </header>
      </div>
    </>
  );
}

export default Header;