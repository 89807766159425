import '../../index.css';
import './style.css';

import { SocialIcon } from 'react-social-icons';

function SocialMedia() {
  return (
    <>
      <div className="social">
        <div className="item">
          <SocialIcon
            url="https://www.facebook.com/Neum%C3%A1tico-Cheinwz-2917228551627053/"
            target="_blank"
            bgColor="black"
          />
        </div>
        <div className="item">
          <SocialIcon
            url="https://www.youtube.com/watch?v=boOrOQ-FLmI&list=OLAK5uy_mQqpfymM8WCffxQWfIcDSjN00_Z7d6gUk"
            target="_blank"
            bgColor="black"
          />
        </div>
        <div className="item">
          <SocialIcon
            url="https://twitter.com/neumaticocheinw?s=08"
            target="_blank"
            bgColor="black"
          />
        </div>
        <div className="item">
          <SocialIcon
            url="https://open.spotify.com/artist/1MisFWN36PDeWG58Knd8Cs"
            target="_blank"
            bgColor="black"
          />
        </div>
        <div className="item">
          <SocialIcon
            url="https://www.instagram.com/neumaticocheinwz/?igshid=1bs9oglh9xfpr"
            target="_blank"
            bgColor="black"
          />
        </div>
      </div>
    </>
  );
}

export default SocialMedia;