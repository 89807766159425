import image1 from './img/disc1-red.jpg';
import image2 from './img/2.jpg';
import image3 from './img/11.jpg';
import image4 from './img/5.jpg';
import image5 from './img/disc2-red.jpeg';
import image6 from './img/4.jpg';

export const contentCard = [{
  preview: 'preview-1',
  positionDeco: '--bottom',
  rotateInner: '--rotated1',
  rotateText: 'cd',
  titleDirection: '--right',
  titleUpOrDown: '--top',
  textDirection: '--right',
  textUpOrDown: '--top',
  text: '"Not is a crime"',
  title: '2021',
  hoverTitle: '2021',
  image: image5
},
{
  preview: 'preview-2',
  positionDeco: '--bottom',
  rotateInner: '--rotated1',
  rotateText: 'single',
  titleDirection: '--right',
  titleUpOrDown: '--top',
  textDirection: '--right',
  textUpOrDown: '--top',
  text: '"1990 llueven hostias en cualquier lugar..."',
  title: '1990',
  hoverTitle: '1990',
  image: image1
},
{
  preview: 'preview-3',
  positionDeco: '--top',
  rotateInner: '--rotated2',
  rotateText: 'power',
  titleDirection: '--straight',
  titleUpOrDown: '--bottom',
  textDirection: '--straight',
  textUpOrDown: '--bottom',
  text: '',
  title: 'bio',
  hoverTitle: 'bio',
  image: image2,
  decoration: '✞'
},
{
  preview: 'preview-4',
  positionDeco: '--bottom',
  rotateInner: '--rotated1',
  rotateText: 'pop',
  titleDirection: '--left',
  titleUpOrDown: '--straight',
  textDirection: '--top',
  textUpOrDown: '--bottom',
  text: '',
  title: 'fotos',
  hoverTitle: 'fotos',
  image: image3,
  decoration: '✰'
},
{
  preview: 'preview-5',
  positionDeco: '--top',
  rotateInner: '--rotated2',
  rotateText: 'noise',
  titleDirection: '--straight',
  titleUpOrDown: '--bottom',
  textDirection: '--right',
  textUpOrDown: '--top',
  text: '',
  title: 'contacto',
  hoverTitle: 'contacto',
  image: image6
},
{
  preview: 'preview-6',
  positionDeco: '--bottom',
  rotateInner: '--rotated1',
  rotateText: 'yeah',
  titleDirection: '--left',
  titleUpOrDown: '--straight',
  textDirection: '--top',
  textUpOrDown: '--bottom',
  text: '',
  title: 'conciertos',
  hoverTitle: 'conciertos',
  image: image4,
  decoration: '✰'
}
]