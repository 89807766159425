
import '../index.css';

function Card({
  preview,
  positionDeco,
  rotateInner,
  textDirection,
  textUpOrDown,
  titleDirection,
  titleUpOrDown,
  text,
  title,
  hoverTitle,
  rotateText,
  image,
  decoration
}) {
  return (
    <a className="grid__item" href={`#${preview}`}>
      <div className="box">
        <div className="box__shadow"></div>
        <img className="box__img" src={image} alt="Some" />
        <h3 className={`box__title ${titleDirection && `box__title${titleDirection }`} ${titleUpOrDown && `box__title${titleUpOrDown}`}`}>
          <span className="box__title-inner" data-hover={hoverTitle}>{title}</span>
        </h3>
        <h4 className={`box__text ${textDirection && `box__text${textDirection}`} ${textUpOrDown && `box__text${textUpOrDown}`}`}>
          <span className={`box__text-inner ${rotateInner && `box__text-inner${rotateInner}`}`}>{rotateText}</span>
        </h4>
        <div className={`box__deco box__deco${positionDeco}`}>{decoration}</div>
        <p className="box__content"> {text} </p>
      </div>
    </a>
  );
}

export default Card;
