import './style.css';

import Gallery from 'react-grid-gallery';
import image1 from './img/1.jpeg';
import image10 from './img/10.jpeg';
import image11 from './img/11.jpeg';
import image12 from './img/12.jpeg';
import image13 from './img/13.jpeg';
import image14 from './img/14.jpeg';
import image15 from './img/15.jpeg';
// import image16 from './img/16.jpeg';
import image17 from './img/17.jpeg';
import image18 from './img/18.jpeg';
import image19 from './img/19.jpeg';
import image2 from './img/2.jpeg';
import image20 from './img/20.jpeg';
import image21 from './img/21.jpeg';
import image22 from './img/22.jpeg';
import image23 from './img/23.jpeg';
import image24 from './img/24.jpeg';
import image25 from './img/25.jpeg';
import image26 from './img/26.jpeg';
import image3 from './img/3.jpeg';
import image4 from './img/4.jpeg';
import image5 from './img/5.jpeg';
import image6 from './img/6.jpeg';
import image7 from './img/7.jpeg';
import image8 from './img/8.jpeg';
import image9 from './img/9.jpeg';

const IMAGES = [
  {
    src: image1,
    thumbnail: image1,
    thumbnailWidth: 200,
    thumbnailHeight: 174,
  },
  {
    src: image2,
    thumbnail: image2,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image3,
    thumbnail: image3,
    thumbnailWidth: 200,
    thumbnailHeight: 212,
  },
  {
    src: image4,
    thumbnail: image4,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image5,
    thumbnail: image5,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image6,
    thumbnail: image6,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image7,
    thumbnail: image7,
    thumbnailWidth: 220,
    thumbnailHeight: 212,
  },
  {
    src: image8,
    thumbnail: image8,
    thumbnailWidth: 350,
    thumbnailHeight: 212,
  },
  {
    src: image9,
    thumbnail: image9,
    thumbnailWidth: 180,
    thumbnailHeight: 212,
  },
  {
    src: image10,
    thumbnail: image10,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image11,
    thumbnail: image11,
    thumbnailWidth: 220,
    thumbnailHeight: 212,
  },
  {
    src: image12,
    thumbnail: image12,
    thumbnailWidth: 180,
    thumbnailHeight: 212,
  },
  {
    src: image13,
    thumbnail: image13,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image14,
    thumbnail: image14,
    thumbnailWidth: 220,
    thumbnailHeight: 212,
  },
  {
    src: image15,
    thumbnail: image15,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  // {
  //   src: image16,
  //   thumbnail: image16,
  //   thumbnailWidth: 320,
  //   thumbnailHeight: 212,
  // },
  {
    src: image17,
    thumbnail: image17,
    thumbnailWidth: 220,
    thumbnailHeight: 212,
  },
  {
    src: image18,
    thumbnail: image18,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image19,
    thumbnail: image19,
    thumbnailWidth: 220,
    thumbnailHeight: 212,
  },
  {
    src: image20,
    thumbnail: image20,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image21,
    thumbnail: image21,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image22,
    thumbnail: image22,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image23,
    thumbnail: image23,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image24,
    thumbnail: image24,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image25,
    thumbnail: image25,
    thumbnailWidth: 320,
    thumbnailHeight: 212,
  },
  {
    src: image26,
    thumbnail: image26,
    thumbnailWidth: 220,
    thumbnailHeight: 212,
  }
];



function Slide() {
  return (
    <>
      <div> - </div>
      <Gallery
        images={IMAGES}
        maxRows={3}
        // backdropClosesModal
        // currentImage={3}
        // isOpen={ true}
      />
    </>
  );
}

export default Slide;